<template>
  <b-card-code>
    <b-row>
      <b-col sm="6">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :disabled="loading"
          @click="download(rows)"
        >
          Exportar a Excel
        </b-button>
      </b-col>
      <b-col sm="6">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Buscar</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Buscar"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :is-loading="loading"
      :rows="rows"
      :rtl="direction"
      :fixed-header="true"
      max-height="500px"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        No se encontraron datos
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Status -->
        <span v-if="props.column.field === 'status.status'">
          <b-badge :variant="statusVariant(props.row.status.status)">
            {{ props.row.status.status }}
          </b-badge>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25', '50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registros </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-code>
</template>

<script>
import {
  BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  BButton,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { downloadExcel } from '@core/utils/utils'
import store from '@/store/index'
import environment from '@/environment'

export default {
  name: 'CentersTypesVue',
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BCardCode,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: '# Centro Origen',
          field: 'originCenterId',
        },
        {
          label: 'Centro Origen',
          field: 'originCenter.centro',
        },
        {
          label: 'Cuenta Origen',
          field: 'originAccount',
        },
        {
          label: '# Centro Destino',
          field: 'destinationCenterId',
        },
        {
          label: 'Centro Destino',
          field: 'destinationCenter.centro',
        },
        {
          label: 'Unidad',
          field: 'unit',
        },
        {
          label: 'Unidades',
          field: 'units',
        },
        {
          label: 'Tipo',
          field: 'type',
        },
        {
          label: 'Estado',
          field: 'status.status',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Preactivo: 'light-preactivoCda',
        Inactivo: 'light-secondary',
        Activo: 'light-primary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statuses() {
      return this.$store.getters['catalog/allStatuses']
    },
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        const respC = await this.$http.get(`${environment.uri}/ccenters/all`)
        this.centers = respC.data
        const resp = await this.$http.get(`${environment.uri}/accounts/auxiliar/idCuenta/0/idCentro/0`)

        if (resp && resp.data) {
          resp.data.forEach(item => {
            const push = {
              destinationCenterId: item.idCentroDestino,
              destinationCenter: this.centers.find(s => s.idCentro === item.idCentroDestino),
              originCenterId: item.idCentroOrigen,
              originCenter: this.centers.find(s => s.idCentro === item.idCentroOrigen),
              originAccount: item.idCuentaOrigen,
              type: item.idTipoAuxiliar,
              unit: item.idUnidad,
              units: item.idUnidades,
              status: this.statuses.find(s => s.idStatus === item.idStatus),
            }

            this.rows.push(push)
          })
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    download(rows) {
      console.log(rows[0])
      const items = rows.map(item => ({
        originCenterId: item.originCenterId,
        originCenter: ((item.originCenter || { centro: '' }).centro).replace(/,/g, ' '),
        originAccount: item.originAccount,
        destinationCenterId: item.destinationCenterId,
        destinationCenter: ((item.destinationCenter || { centro: '' }).centro).replace(/,/g, ' '),
        unit: item.unit,
        units: item.units,
        type: item.type,
        status: (item.status.status || { status: '' }),
      }))
      downloadExcel(items, [
        '# Centro Origen',
        'Centro Origen',
        'Cuenta Origen',
        '# Centro Destino',
        'Centro Destino',
        'Unidadad',
        'Unidades',
        'Tipo',
        'Estado',
      ], 'report-aux.csv')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
